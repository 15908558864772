<template>
  <div class="content account-detail">
    <div class="base-info">
      <div class="infoTitle">
        <div class="leftLine"></div>
        {{ $t('基本信息') }}
      </div>
      <div class="form-content fixContent">
        <a-form-model ref="ruleForm">
          <a-row :gutter="16">
            <a-col :span="8">
              <a-form-model-item :label="$t('编码')">
                <a-input />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item :label="$t('名称')">
                <a-input />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item :label="$t('上级')">
                <a-input />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item :label="$t('余额方向')">
                <a-input />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
export default {
  name: 'accountDetail',
  data() {
    return {}
  },
  mounted() {
    if (this.$route.query.id) {
      http({
        url: api.getAccountDetail,
        data: { id: this.$route.query.id },
      }).then((res) => {
        console.log(res)
      })
    }
  },
  methods: {},
}
</script>
